<template>
  <v-container fluid pt-0 style="margin: auto;">
    <v-row mb-3>
      <v-col sm="10" md="9" lg="6" class="mx-auto">
        <steps :steps="5" :current="2" />
      </v-col>
    </v-row>

    <v-row mb-3 v-if="ajaxError">
      <v-col sm="10" md="9" lg="6" class="mx-auto">
        <v-alert
          :value="true"
          type="error"
          closable
        >
          {{ ajaxError }}
        </v-alert>
      </v-col>
    </v-row>

    <v-row mb-3>
      <v-col sm="10" md="9" lg="6" class="mx-auto">
        <h1>Select by Brand</h1>
      </v-col>
    </v-row>

    <v-row mb-3>
      <v-col sm="10" md="9" lg="6" class="mx-auto">
        <SearchInput
          input-placeholder-text="Search by brand"
          @search="searchQuery($event)"
          @clear="resetBrands()"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col sm="10" md="9" lg="6" class="mx-auto">
        <v-sheet
          color="white"
          elevation="2"
          rounded
        >
          <v-row>
            <v-col
              v-for="brand in brands"
              :key="brand.id"
              cols="6"
              xs="12"
              sm="6"
              md="4"
              lg="4"
              xl="4"
            >
              <v-card
                flat
                rounded="0"
                ripple
                class="brand-card clickable pt-6"
                @click="setBrand(brand)"
              >
                <v-icon
                  v-if="brand.description"
                  class="top-right-icon"
                  @click.stop="handleIconClick(brand)"
                >
                  {{ selectedInfoBrand && selectedInfoBrand.id === brand.id ? 'mdi-close' : 'mdi-information-outline' }}
                </v-icon>
                <v-img
                  :src="brand.icon_logo_path"
                  aspect-ratio="1.7778"
                >
                </v-img>
                <div v-if="brand.description" :class="['title-container', { animate: selectedInfoBrand && selectedInfoBrand.id === brand.id }]">
                  <v-card-title
                    class="text-center text-uppercase card-title"
                    style="white-space: normal; height: 7rem;"
                  >
                    <div class="brand-name">
                      {{ brand.name }}
                    </div>

                    <div class="brand-description">
                      {{ brand.description }}
                    </div>
                  </v-card-title>
                </div>
                <div v-else>
                  <v-card-title
                    class="text-center text-uppercase card-title"
                    style="white-space: normal; height: 7rem;"
                  >
                    <div class="brand-name">
                      {{ brand.name }}
                    </div>
                  </v-card-title>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>

    <v-row mb-0>
      <v-col sm="10" md="9" lg="6" class="mx-auto">
        <v-alert
          :value="true"
          type="info"
          v-if="activeBrand"
        >
          Your selection: <strong>{{ activeBrand.name }}</strong>
        </v-alert>
        <v-alert
          :value="true"
          type="info"
          v-else
        >
          You must select a brand before continue.
        </v-alert>
      </v-col>
    </v-row>

    <v-row mb-3 v-if="checkBrandError">
      <v-col sm="10" md="9" lg="6" class="mx-auto">
        <v-alert
          :value="true"
          type="error"
          closable
        >
          {{ checkBrandError }}
        </v-alert>
      </v-col>
    </v-row>

    <v-row mb-5>
      <v-col sm="10" md="9" lg="6" class="mx-auto">
        <v-btn
          id="nxt-btn"
          class="text-white mb-3"
          block
          rounded
          variant="flat"
          :disabled="toggleButton"
          size="large"
          color="#171b60"
          @click="checkBrand"
        >
          Next
          <v-icon>mdi-menu-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ref, onMounted } from 'vue';
import apiClientService from '../services/apiClientService.js';
import searchTerm from '../components/searchTerm.js'
import SearchInput from '../components/searchInput.vue';
import { useStore } from '../store/store'
import { useRouter } from 'vue-router'

export default {
  components: {
    SearchInput
  },
  setup() {
    const userStore = useStore();
    const ajaxError = ref(false);
    const checkBrandError = ref(false);
    const brands = ref([]);
    const brandsBackup = ref([]);
    const activeBrand = ref(null);
    const toggleButton = ref(true);
    const router = useRouter();
    const selectedInfoBrand = ref(null);

    onMounted(() => {
      document.getElementById("loading").style.display = "block";
      
      apiClientService.getPrimaryBrand(userStore.currentUser.id)
        .then(response => {
          userStore.currentUser.brand = response.data;
          ajaxError.value = false

          apiClientService.getBrandsWithoutOnesUserHas(userStore.currentUser.id)
            .then(response => {
              brands.value = response.data
              brandsBackup.value = brands.value
              ajaxError.value = false
              document.getElementById("loading").style.display = "none"
            })
            .catch(error => {
              ajaxError.value = error.message
              document.getElementById("loading").style.display = "none"
            })
        })
        .catch(error => {
          ajaxError.value = error.message
          document.getElementById("loading").style.display = "none"
        })
    });

    const checkBrand = () => {
      if (userStore.currentUser.brand.id !== activeBrand.value.id) {
        router.push({ name: 'sale-already-happened' })
      } else {
        checkBrandError.value = "You can't select your brand";
      }
    };

    const setBrand = (brand) => {
      activeBrand.value = brand;
      toggleButton.value = false;
        useStore().setBrand(brand);
        useStore().setPathInfo('By Brand');
        scrollToBottom();
      // Adjust the scrolling logic if needed
      checkBrandError.value = null;
    };

    const scrollToBottom = () => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth'
      });
    };

    const searchQuery = (query) => {
      if (query.length >= 2) {
          brands.value = searchTerm(brandsBackup.value, query)
        } else
          brands.value = brandsBackup.value
    };

    const resetBrands = () => {
      brands.value = brandsBackup.value
        // this.panel = []
    };

    const handleIconClick = (brand) => {
      if (selectedInfoBrand.value && selectedInfoBrand.value.id === brand.id) {
        selectedInfoBrand.value = null;
      } else if (window.innerWidth <= 500) {
        selectedInfoBrand.value = brand;
      }
    };

    return {
      ajaxError,
      checkBrandError,
      brands,
      activeBrand,
      toggleButton,
      selectedInfoBrand,
      checkBrand,
      setBrand,
      searchQuery,
      resetBrands,
      handleIconClick
    };
  }
}
</script>

<style scoped>
  @media (max-width: 500px) {
    .v-card-title {
      height: 4.5rem !important;
      font-size: 0.75rem;
      line-height: 1.75 !important;
      padding: 0.25rem;
    }
  }

  .brand-card {
    position: relative;
    overflow: hidden;
    transition: box-shadow 0.3s ease;
  }

  .top-right-icon {
    position: absolute;
    top: 8px;
    right: 8px;
    color: #000;
    font-size: 24px;
    z-index: 2;
    cursor: pointer;
  }

  /* -------------------------------- */

  .title-container {
    position: relative;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }

  .card-title,
  .brand-description {
    transition: transform 0.3s ease, opacity 0.3s ease, background-color 0.3s ease;
    background-color: transparent;
    color: inherit;
  }

  .brand-description {
    opacity: 0;
    max-height: 0;
    transition: opacity 0.3s ease, max-height 0.3s ease, transform 0.3s ease, background-color 0.3s ease;
    overflow: hidden;
    text-align: center;
    margin-top: 2rem;
    font-size: 14px;
    line-height: 1;
  }

  @media (max-width: 500px) {
    .brand-description {
      margin-top: 1.5rem;
      font-size: 10px;
    }
  }

  /* For desktop screens */
  @media (hover: hover) and (pointer: fine) {
    .top-right-icon:hover ~ .title-container .card-title {
      opacity: 0.9;
      background-color: rgba(0, 0, 0, 0.5);
      color: #fff;
    }

    .top-right-icon:hover ~ .title-container .brand-description {
      transform: translateY(-20px);
      opacity: 1;
      max-height: 10rem;
      color: #fff;
    }

    .top-right-icon:hover ~ .title-container .brand-name {
      display: none;
    }
  }

  /* For mobile screens */
  .animate .card-title {
    opacity: 0.9;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
  }

  .animate .brand-description {
    transform: translateY(-20px);
    opacity: 1;
    max-height: 10rem;
    color: #fff;
  }

  .animate .brand-name {
    display: none;
  }
</style>
